<template>
  <Dialog class="p-col-8" style="padding: 0" v-model:visible="display" @hide="close()">
    <Toast/>
    <template #header>
      <h3>{{ title }}招聘职位</h3>
    </template>
    <div class="card">
      <div class="p-fluid">
        <div style="text-align: left">
          <div class="p-grid">
            <div class="p-field p-col-6">
              <label>职位名称：</label>
              <InputText v-model="from.job"></InputText>
            </div>
          </div>
        </div>
        <div style="text-align: left">
          <h6 style="font-weight: bold">薪资待遇</h6>
          <div class="p-grid">
            <div class="p-field p-col">
              <label>最低薪资：</label>
              <InputText v-model="from.minSalary"></InputText>
            </div>
            <div class="p-field p-col">
              <label>最高薪资：</label>
              <InputText v-model="from.maxSalary"></InputText>
            </div>
            <div class="p-field p-col-6">
              <label>公司地址：</label>
              <InputText v-model="from.addr"></InputText>
            </div>
            <div class="p-field p-col-12">
              <label>福利待遇：</label>
              <div style="display: flex">
              <div class="p-field-checkbox">
                <Checkbox id="city1" v-model="welfareList" name="welfare" value="五险一金"/>
                <label for="city1">五险一金</label>
              </div>
              <div class="p-field-checkbox p-mr-2">
                <Checkbox id="city2" v-model="welfareList" name="welfare" value="技能培训"/>
                <label for="city2">技能培训</label>
              </div>
              <div class="p-field-checkbox p-mr-2">
                <Checkbox id="city3" v-model="welfareList" name="welfare" value="年度旅游"/>
                <label for="city3">年度旅游</label>
              </div>
              <div class="p-field-checkbox p-mr-2">
                <Checkbox v-model="welfareList" name="welfare" value="带薪休假"/>
                <label>带薪休假</label>
              </div>
              <div class="p-field-checkbox p-mr-2">
                <Checkbox v-model="welfareList" name="welfare" value="加班补助"/>
                <label>加班补助</label>
              </div>
              <div class="p-field-checkbox p-mr-2">
                <Checkbox v-model="welfareList" name="welfare" value="节日福利"/>
                <label>节日福利</label>
              </div>
              <div class="p-field-checkbox p-mr-2">
                <Checkbox v-model="welfareList" name="welfare" value="其他"/>
                <label>其他</label>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: left" class="p-col-12 p-grid">
          <h6 style="font-weight: bold" class="p-col-12">岗位要求：</h6>
          <div class="p-field p-col">
            <label>学历要求：</label>
            <Dropdown v-model="from.degree" :options="degreeList" optionLabel="name"
                      option-value="name"
                      placeholder="学历要求" display="chip" showClear="true"/>
          </div>
          <div class="p-field p-col">
            <label>专业要求：</label>
            <InputText style="height: 34px;" v-model="from.major"></InputText>
          </div>
          <div class="p-field p-col">
            <label>性别要求：</label>
            <Dropdown v-model="from.sex" :options="sexList" optionLabel="name"
                      option-value="name"
                      placeholder="性别要求" display="chip" showClear="true"/>
          </div>
          <div class="p-field p-col">
            <label>最小年龄：</label>
            <InputText v-model="from.minAge"></InputText>
          </div>
          <div class="p-field p-col">
            <label>最大年龄：</label>
            <InputText v-model="from.maxAge"></InputText>
          </div>

        </div>
      </div>
      <div style="text-align: left;" class="p-grid p-fluid ">
        <div class="p-field p-col-12 p-md-6 ">
          <label>工作经验 （年）：</label>
          <InputText v-model="from.workTime" type="text"/>
        </div>
        <div class="p-field p-col-12 p-md-6 ">
          <label>开始时间：</label>
          <Calendar  class="p-ml-3" v-model="from.beginTime"
                     placeholder="开始日期" dateFormat="yy-mm-dd" showButtonBar="true"/>
        </div>
        <div class="p-field p-col-12 p-md-6 ">
          <label>工作时间：</label>
          <InputNumber v-model="from.date"
                       placeholder="工作时间（天）" :useGrouping="false"/>
        </div>
        <div class="p-field p-col-12 ">
          <label>岗位职责：</label>
          <div>
            <textarea v-model="from.duty" style="height: 100px" class="p-col-12" field="name"></textarea>
          </div>
        </div>
        <div class="p-field p-col-12 ">
          <label>岗位要求：</label>
          <div>
            <textarea v-model="from.request" style="height: 100px" class="p-col-12" field="name"></textarea>
          </div>
        </div>
        <h6 style="font-weight: bold; margin-top: 0" class="p-col-12">负责人员：</h6>
        <div class="p-field p-col-12 p-md-6 ">
          <label>负责人员：</label>
          <InputText v-model="from.person" type="text"/>
        </div>
        <div class="p-field p-col-12 p-md-6 ">
          <label>联系方式：</label>
          <InputText v-model="from.phone"></InputText>
        </div>
      </div>
    </div>

    <div class="p-grid p-col-12 p-mt-6 p-jc-around">
      <Button label="取消" @click="display=false" class="p-mr-2" icon="pi pi-replay"/>
      <Button label="保存" class="p-mr-2" @click="submit" icon="pi pi-save"/>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      welfareList:[],
      title: false,
      display: false,
      from: {
        code: null,
        job: null,
        degree: null,
        minSalary: null,
        maxSalary: null,
        recruitmentStatus: null,
        welfare: null,
        addr: null,
        phone: null,
        person: null,
        request: null,
        duty: null,
        workTime: null,
        maxAge: null,
        minAge: null,
        sex: null,
        major: null,
        beginTime:null,
        date:null
      },
      degreeList: [],
      sexList: []
    }
  },
  mounted() {
    this.$http.get('/dictionary/selectValue', {params: {key: 'xueliyaoqiu'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      this.degreeList = resList;
    })
    this.$http.get('/dictionary/selectValue', {params: {key: 'xingbie'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      this.sexList = resList;
    })
  },
  methods: {
    init(info) {
      this.display = true
      if (info != null) {
        this.title = "编辑"
        let list = info.welfare.split(',')
        this.welfareList=list
        console.log(this.welfareList)
        this.from = info
      }
    },
    close() {
      this.$emit('close', false)
    },
    submit() {

      let str = '';
      for(let i =0;i<this.welfareList.length;i++){
        if(i<this.welfareList.length-1){
          str = str+this.welfareList[i]+','
        }else {
          str = str+this.welfareList[i]
        }
      }
      this.from.welfare = str
      this.$http.post('/companies/updateRecruit', this.from).then(() => {
        console.log(this.from)
        this.$toast.add({severity: 'info', summary: '成功', detail: '操作成功', life: 3000})
        this.display = false
      })
    }
  }
}
</script>

<style scoped>

</style>
