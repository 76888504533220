<template>
  <div class="p-grid card page">
    <div style="width:100%">
      <Breadcrumb :model="items"/>
      <DataTable :value="show" class="p-mt-5"
                 :scrollable="true" responsiveLayout="scroll" :auto-layout="true"
                 :paginator="true" :rows="10"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[5,10,25]"
                 currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">
        <template #header>
          <div class="p-d-flex" style="text-align: left">
            <Button @click="goto()" label="添加职位" icon="pi pi-fw pi-plus"/>
            <!-- <Button class="p-button-sm" label="关键词"/> -->
            <InputText v-model="job" @change="showAll" class="p-ml-2" placeholder="职位/企业名称" type="text"/>
            <Dropdown @change="showAll" v-model="recruitmentStatus" :options="states" class="p-ml-2" optionLabel="name"
                      option-value="code" placeholder="招聘状态" :show-clear="true"/>
            <Button class="p-ml-2" @click="showAll">查询</Button>
          </div>
        </template>
        <Column style="min-width: 20rem" field="companiesName" header="招聘企业"></Column>
        <Column style="min-width: 20rem" field="job" header="职位名称"></Column>
        <Column style="min-width: 10rem" field="degree" header="学历"></Column>
        <Column style="min-width: 20rem" field="minSalary" header="薪资待遇">
          <template #body="slotProps">
            {{ slotProps.data.minSalary }}-{{ slotProps.data.maxSalary }} 元/人.月
          </template>
        </Column>
        <Column style="min-width: 10rem" field="recruitmentStatus" header="审核状态">
          <template #body="slotProps">
            <label v-if="slotProps.data.recruitmentStatus==0">待审核</label>
            <label v-if="slotProps.data.recruitmentStatus==1">招聘中</label>
            <label v-if="slotProps.data.recruitmentStatus==2">拒绝</label>
          </template>
        </Column>
        <Column style="min-width: 20rem" field="time" header="发布时间">
          <template #body="slotProps">
            {{
              new Date(+new Date(slotProps.data.time) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            }}
          </template>
        </Column>
        <Column header="操作" frozen alignFrozen="right" style="min-width:24rem;">
          <template #body="slotProps">
            <Button v-if="userType==1" class="p-button-sm p-mr-2" @click="success(slotProps.data.id)">
              {{ slotProps.data.recruitmentStatus == 1 ? '拒绝' : '通过' }}
            </Button>
            <Button class="p-button-sm p-mr-2" @click="update(slotProps.data)">编辑</Button>
            <Button class="p-button-sm p-mr-2" @click="toUserList(slotProps.data)">查看报名人员</Button>
            <Button class="p-button-sm p-button-danger" @click="confirmDeleteProduct(slotProps.data)">删除</Button>
          </template>
        </Column>
      </DataTable>
      <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
          <span v-if="product">你确认要删除 <b>{{ recruit.job }}</b>?</span>
        </div>
        <template #footer>
          <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
          <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(recruit.id)"/>
        </template>
      </Dialog>
      <EditRecruit v-if="display" ref="update" v-on:close="diaLogClose"></EditRecruit>
      <AddRecruitList v-on:close="closeAdd" v-if="showAdd" ref="addRecruit"></AddRecruitList>
    </div>
  </div>
  <Toast/>
</template>

<script>
import EditRecruit from "@/views/modules/recruit/EditRecruit";
import AddRecruitList from "@/views/modules/recruit/AddRecruitList";

export default {
  name: "RecruitList",
  components: {EditRecruit, AddRecruitList},
  data() {
    return {
      userType: null,
      showAdd: false,
      job: null,
      recruitmentStatus: null,
      show: [],
      items: [
        {label: '招聘管理', to: '/recruit/recruitList'},
        {label: '企业招聘列表', to: '/recruit/recruitList'}
      ],
      states: [
        {name: '招聘中', code: '1'},
        {name: '待审核', code: '0'},
        {name: '拒绝', code: '2'},
      ],
      deleteProductDialog: false,
      product: {},
      recruit: {},
      display: false,
    }
  },
  mounted() {
    this.userType = JSON.parse(localStorage.getItem('userInfo')).userType
    this.showAll();
  },
  methods: {
    goto() {
      this.showAdd = true
      this.$nextTick(() => {
        this.$refs.addRecruit.init()
      })
    },
    toUserList(data) {
      this.$router.push('/RecruitUserList?id=' + data.id)
    },
    rest() {
      this.showAll()
    },
    submitSelect() {
      this.$http.get('/companies/recruit', {
        params: {
          'recruitmentStatus': this.recruitmentStatus,
          'job': this.job,
        }
      }).then((res) => {
        console.log(res.data)
        this.show = res.data
        this.$toast.add({severity: 'success', summary: '成功', detail: '查询成功', life: 3000});
      })
    },
    showAll() {
      this.$http.get('/companies/getAll', {
        params: {
          state: this.recruitmentStatus,
          text: this.job
        }
      }).then((res) => {
        console.log(res.data)
        this.show = res.data
      })
    },
    confirmDeleteProduct(recruit) {
      this.recruit = recruit;
      this.deleteProductDialog = true;
    },
    deleteProduct(id) {
      this.$http.get('/companies/delRecruit', {
        params: {id: id}
      }).then(() => {
        this.deleteProductDialog = false;
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.showAll()
      })
      console.log(id)
    },

    update(info) {
      this.display = true
      this.$nextTick(() => {
        this.$refs.update.init(info)
      })
    },
    diaLogClose(e) {
      this.display = e
      this.showAll()
    },
    closeAdd() {
      this.showAdd = false;
      this.showAll()
    },
    success(id) {
      this.$http.post('/companies/audit/' + id).then((res) => {
        if (res.data == null) {
          this.$toast.add({severity: 'error', summary: '出错了', life: 3000});
        } else {
          if (res.data == 1) {
            this.$toast.add({severity: 'success', summary: '已通过', life: 3000});
          } else {
            this.$toast.add({severity: 'success', summary: '已拒绝', life: 3000});
          }
        }
        this.showAll()
      })
    }
  }
}
</script>

<style scoped>

</style>